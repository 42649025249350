import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container, Button, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Preloader from './Preloader';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './App.css'; // Importing CSS

const moveData = {
  mouseX: 0,
  mouseY: 0,
  targetX: 0,
  targetY: 0,
  currentX: 0,
  currentY: 0
};

function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end;
}

function App() {

  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const handleMouseMove = (event) => {
      moveData.targetX = (window.innerWidth / 2 - event.clientX) * 0.05; // Opposite direction
      moveData.targetY = (window.innerHeight / 2 - event.clientY) * 0.05;
    };

    const updatePosition = () => {
      // Linear interpolation for smoother movement
      moveData.currentX = lerp(moveData.currentX, moveData.targetX, 0.05);
      moveData.currentY = lerp(moveData.currentY, moveData.targetY, 0.05);

      const leftImage = document.querySelector('.parallax-image-left');
      const rightImage = document.querySelector('.parallax-image-right');

      if (leftImage && rightImage) {
        leftImage.style.transform = `translate(-50%, -50%) translate(${moveData.currentX}px, ${moveData.currentY}px)`;
        rightImage.style.transform = `translate(50%, -50%) translate(${moveData.currentX}px, ${moveData.currentY}px)`;
      }

      // Update TOKEN image position
      const tokenImage = document.querySelector('.token-image');
      if (tokenImage) {
        tokenImage.style.transform = `translate(${moveData.currentX}px, ${moveData.currentY}px)`;
      }

      // Apply the effect to the roadmap images as well
      const roadmapImages = document.querySelectorAll('.roadmap-image-parallax');
      roadmapImages.forEach(image => {
        image.style.transform = `translate(${moveData.currentX}px, ${moveData.currentY}px)`;
      });

      requestAnimationFrame(updatePosition);
    };

    window.addEventListener('mousemove', handleMouseMove);
    updatePosition();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  
    const setupAnimations = () => {
      const roadmap = document.querySelector('.roadmap');
      const roadmapSections = document.querySelectorAll('.roadmap-section');

      if (roadmap && roadmapSections.length) {
        console.log('Setting up ScrollTrigger for Roadmap'); // Debugging log

        gsap.to('.roadmap-progress-bar', {
          height: '100%',
          ease: 'none',
          scrollTrigger: {
            trigger: '.roadmap',
            start: 'top bottom',
            end: 'bottom top',
            scrub: true
          }
        });

        roadmapSections.forEach(section => {
          let circle = section.querySelector('.roadmap-circle');
          if (circle) {
            ScrollTrigger.create({
              trigger: section,
              start: 'top center',
              end: 'bottom center',
              onEnter: () => gsap.set(circle, { visibility: 'visible' }),
              onLeaveBack: () => gsap.set(circle, { visibility: 'hidden' }),
              markers: true // For debugging purposes
            });
          }
        });
      } else {
        console.log('Roadmap or Sections not found'); // Debugging log
      }
    };

    // Delay the setup to ensure elements are available
    const delay = 1000; // Adjust this delay as needed
    setTimeout(setupAnimations, delay);

  }, []);

  if (loading) {
    return <Preloader setLoading={setLoading} />;
  }

  return (
    <div className="App">
      <div className="full-screen-section">
      <img src={`${process.env.PUBLIC_URL}/1.png`} alt="Background Left" className="parallax-image-left" />
      <img src={`${process.env.PUBLIC_URL}/2.png`} alt="Background Right" className="parallax-image-right" />
      {/* Navbar Section */}
      <Navbar className="navbar-custom" expand="lg" style={{ backgroundColor: 'transparent' }}>
        <Container fluid>
        <Navbar.Brand className="navbar-brand-custom" href="#home">
          <img
            src={`${process.env.PUBLIC_URL}/l3go-std.png`} // Replace with your logo's filename
            height="80"
            alt="L3GO Logo"
          />
        </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="navbar-collapse-custom" id="basic-navbar-nav">
            <Nav className="ms-auto"> {/* ms-auto will push the nav items to the right */}
              <Nav.Link href="#home" className="nav-link">HOME</Nav.Link>
              <Nav.Link href="#l3go" className="nav-link">L3GO</Nav.Link>
              <Nav.Link href="#token" className="nav-link">TOKEN</Nav.Link>
              <Nav.Link href="#team" className="nav-link">TEAM</Nav.Link>
              <Nav.Link href="#stake" className="nav-link">STAKE</Nav.Link>
            </Nav>
            <Button className="connect-button">CONNECT</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main Header Section */}
      <div className="text-center main-header">
        <img 
          src={`${process.env.PUBLIC_URL}/enter.svg`} // Replace with your header SVG filename
          alt="Enter the World of L3GO" 
          className="header-image"
        />
      </div>
      <div className="text-center next-header">
        <h2 className="shadow-text">
          L3GO CELEBRATES THE NOSTALGIA OF YOUR FAVORITE <br />
          CHARACTERS WITH A UNIQUE DIGITAL PROOF OF <br />
          OWNERSHIP ON THE ETHEREUM BLOCKCHAIN
        </h2>
      </div>
      {/* ENTER Button Section */}
      <div className="enter-section text-center">
        <a href="https://discord.gg/l3goverse" // Replace with your target URL
                target="_blank" 
                rel="noopener noreferrer" className="enter-text">JOIN DISCORD</a> <br/>
        <a href="#l3go">
        <img 
          src={`${process.env.PUBLIC_URL}/arrow.svg`} // Replace with your V icon's filename
          alt="V Icon" 
          className="v-icon"
        />
        </a>
      </div>
      </div>
      {/* L3GO Section */}
      <div id="l3go" className="l3go-section">
      <img src={`${process.env.PUBLIC_URL}/collection-left-img.png`} alt="Background Left" className="collection-image-left" />
      <img src={`${process.env.PUBLIC_URL}/collection-rightt-img.png`} alt="Background Right" className="collection-image-right" />
        <div className="l3go-left">
          <h1>ABOUT <br />
            L3GO</h1>
        </div>
        <div className="l3go-right">
          <p>
            WELCOME TO THE WORLD OF L3GO – A FUN AND
            LIVELY WEB3 UNIVERSE THAT'LL TICKLE YOUR
            NOSTALGIA AND DELIGHT YOUR INNER COLLECTOR.
            <br />
            <br />
            OUR MISSION? <br />
            TO BRING BACK THE SWEET MEMORIES OF YOUR
            FAVORITE CHILDHOOD TOYS WHILE ADDING A
            MODERN TWIST. LET'S DIVE IN AND REDISCOVER
            THE JOY OF PLAY WITH A WEB3 TWIST!
          </p>
        </div>
      </div>
      {/* L3GO Collection*/}
      <div className="l3go-collection">
        <div className="lcol-container">
          <div className='lcol-left'>
          <img 
            src={`${process.env.PUBLIC_URL}/6.png`} // Replace with your V icon's filename
            alt="l3go1" 
            className="l31-img"
          />
          </div>
          <div className='lcol-right'>
            <h1>THE L3GO COLLECTION</h1>
            <p>
            L3GO ISN'T JUST ABOUT NFTS; IT'S CELEBRATING AND RELIVING THOSE 
            CHERISHED MOMENTS SPENT BUILDING COLORFUL CREATIONS. 
            OUR COLLECTION IS ABOUT SHARING THE JOY OF CREATIVE OWNERSHIP IN THE DIGITAL AGE, 
            REMINISCENT OF YOUR BELOVED TOYS, EACH WITH ITS UNIQUE CHARM AND RARITY.
            </p>
            <a 
              href="YOUR_URL_HERE" // Replace with your target URL
              target="_blank" 
              rel="noopener noreferrer" 
              className="discover-button">
              DISCOVER THE COLLECTION
            </a>
          </div>
        </div>
        <div className='lcol-low'>
          <img src={`${process.env.PUBLIC_URL}/13.png`} alt="L3GO1" />
          <img src={`${process.env.PUBLIC_URL}/14.png`} alt="L3GO2" />
          <img src={`${process.env.PUBLIC_URL}/15.png`} alt="L3GO3" />
          <img src={`${process.env.PUBLIC_URL}/16.png`} alt="L3GO4" />
          </div>
      </div>
      {/* L3GOVerse*/}
      <div className='l3goverse'>
        <div className='l3goverse-left'>
          <h1>L3GOVERSE</h1>
              <h2>
              A web3 metaverse where creativity meets resourcefulness.
              </h2>
              <p>
              The L3GOVERSE is where nostalgia meets the future - a digital playground powered by Web3 technology. 
              Its your chance to relive those childhood moments, build and play experiences, interact with fellow
              players and even earn $L3GO tokens along the way.
              Oh, and did we mention, its free to play!
              </p>
              <a 
                href={`${process.env.PUBLIC_URL}/yellow-paper.pdf`}
                target="_blank" 
                rel="noopener noreferrer" 
                className="discover-button">
                READ THE YELLOW PAPER
              </a>
        </div>
        <div className='l3goverse-right'>
          <img 
              src={`${process.env.PUBLIC_URL}/5.png`}
              alt="l3go1" 
              className="l3verse"
            />
        </div>
      </div>

      {/* ROADMAP Section */}
      <div id="roadmap" className="roadmap">
      <div className="roadmap-progress-bar"></div>
        {/* Start Subsection */}
        <div className="roadmap-section start">
        <div className="roadmap-circle" style={{ top: '50%' }}></div>
            <div className="roadmap-text">
              <h2>NFT RELEASE</h2>
              <p>WE KICKSTART THE ADVENTURE WITH THE LAUNCH OF OUR L3GO COLLECTION, EACH PIECE A UNIQUE TRIP DOWN MEMORY LANE.</p>
            </div>
            <div className="roadmap-image-container">
              <img src={`${process.env.PUBLIC_URL}/step1.png`} alt="Start" className="roadmap-image roadmap-image-parallax"/>
            </div>
        </div>

        {/* Mid Subsection */}
        <div className="roadmap-section mid">
        <div className="roadmap-circle" style={{ top: '50%' }}></div>
            <div className="roadmap-image-container">
              <img src={`${process.env.PUBLIC_URL}/step2.png`} alt="Mid" className="roadmap-image roadmap-image-parallax"/>
            </div>
            <div className="roadmap-text">
              <h2>TOKEN RELEASE</h2>
              <p>WE INTRODUCE $L3GO, YOUR DIGITAL CURRENCY FOR NAVIGATING THE L3GOVERSE AND EXPERIENCING ITS WONDERS.</p>
            </div>
        </div>

        {/* End Subsection */}
        <div className="roadmap-section end">
        <div className="roadmap-circle" style={{ top: '50%' }}></div>
            <div className="roadmap-text">
              <h2>L3GOVERSE</h2>
              <p>THE GRAND FINALE! A WEB3-POWERED GAMING UNIVERSE WHERE YOU CAN PLAY TO EARN $L3G0 TOKENS. 
                WHETHER YOU'RE AN NFT COLLECTOR OR AN EXPLORER-AT-HEART, 
                THERE'S A WORLD OF NOSTALGIA AND CREATIVITY WAITING FOR YOU.</p>
            </div>
            <div className="roadmap-image-container">
              <img src={`${process.env.PUBLIC_URL}/step3.png`} alt="End" className="roadmap-image roadmap-image-parallax"/>
            </div>
        </div>
      </div>

      {/* TOKEN Section */}
      <div id="token" className="token-section">
        <img src={`${process.env.PUBLIC_URL}/8.png`} alt="Token" className="token-image" />
        <h1 className="token-header">THE $L3GO TOKEN</h1>
        <h2 className="token-subheader">INTRODUCING $L3GO: THE CURRENCY OF CREATIVITY!</h2>
        <p className="token-paragraph">$L3GO IS THE ENGINE FUEL BEHIND OUR PROJECT, FUELING EVERYTHING FROM COLLECTING TO YOUR ADVENTURES IN THE L3GOVERSE.
        DO NOT FORGET TO STAKE YOUR NEWLY MINTED L3GO IN OUR STAKING DAPP FOR AN AIRDROP OF $L3GO!</p>
        <a 
          href={`${process.env.PUBLIC_URL}/yellow-paper.pdf`} // Replace with your target URL
          target="_blank" 
          rel="noopener noreferrer" 
          className="token-button">
          YELLOW PAPER
        </a>
      </div>

      {/* TEAM Section */}
      <div id="team" className="team-section">
        <h1 className="team-header">THE TEAM</h1>

        {/* Team Members */}
        <div className="team-members">
          <div className="team-member">
            <img src={`${process.env.PUBLIC_URL}/team1.jpg`} alt="Bolo Yeung" className="team-image" />
            <h2 className="team-subheader">BOLO YEUNG</h2>
            <p className="team-paragraph">EXECUTIVE ADVISOR</p>
          </div>

          <div className="team-member">
            <img src={`${process.env.PUBLIC_URL}/team2.jpg`} alt="CEX Rogen" className="team-image" />
            <h2 className="team-subheader">CEX ROGEN</h2>
            <p className="team-paragraph">CREATIVE DIRECTOR</p>
          </div>

          <div className="team-member">
            <img src={`${process.env.PUBLIC_URL}/team3.jpg`} alt="Jack Cousto" className="team-image" />
            <h2 className="team-subheader">JACK COUSTO</h2>
            <p className="team-paragraph">BLOCKCHAIN & IT</p>
          </div>
        </div>
      </div>
      
      {/* FOOTER */}
      <div className="footer text-white mt-5 py-4">
        <Container>
          <Row>
            {/* Logo Column */}
            <Col md={3} className="mb-3 mb-md-0 footer-logo-container">
              <img src={`${process.env.PUBLIC_URL}/l3go-std.png`} alt="L3GOVERSE Logo" className="footer-logo" />
            </Col>
            <Col md={3} className="mb-3 mb-md-0">
              <h4 className="footer-heading">LINKS</h4>
              <ul className="list-unstyled footer-links">
                <li><a href="#home" className="footer-link">HOME</a></li>
                <li><a href="#l3go" className="footer-link">L3GO</a></li>
                <li><a href="#token" className="footer-link">L3GO TOKEN</a></li>
              </ul>
            </Col>
            <Col md={3}>
              <h4 className="footer-heading">MORE</h4>
              <ul className="list-unstyled footer-links">
                <li><a href="#team" className="footer-link">THE TEAM</a></li>
                <li><a href="#stake" className="footer-link">STAKING</a></li>
                <li><a href="https://discord.gg/l3goverse" target='blank' className="footer-link">DISCORD</a></li>
              </ul>
            </Col>
            <Col md={3}>
              <h4 className="footer-heading">EVEN MORE</h4>
              <ul className="list-unstyled footer-links">
                <li><a href="https://www.l3go.io/yellow-paper.pdf" className="footer-link">WHITEPAPER</a></li>
                <li><a href="#roadmap" className="footer-link">ROADMAP</a></li>
                <li><a href="https://t.me/l3goverse" target='blank' className="footer-link">TELEGRAM</a></li>
              </ul>
            </Col>
          </Row>
          <div className="text-center mt-4">
            <p>COPYRIGHT © 2024 -- L3GOVERSE -- ALL RIGHTS RESERVED</p>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default App;
