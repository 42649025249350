import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import './Preloader.css'; // Adjust path if necessary

const Preloader = ({ setLoading }) => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/35984/LEGO_loader.json' // Ensure this path is correct
    });
    anim.setSpeed(3.4);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
      anim.destroy(); // Optional: to clean up the animation instance
    };
  }, [setLoading]);

  return (
    <div className="preloader">
      <div ref={animationContainer} className="animation-window"></div>
      <div className="loading-bar-container">
        <div className="loading-bar"></div>
      </div>
    </div>
  );
};

export default Preloader;
